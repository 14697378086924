/* Home.css */

.table-margin {
  margin: 2rem auto;
  max-width: 90%;
}

.card {
  border: black;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.05);
  margin-top: 40px;
  
}

.card-header {
  background-color: #f5f5f5;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.card-body {
  padding: 10px;
}

.card-header {
  margin-top: 50px;
}

.clickable-row {
  cursor: pointer;
}


/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

th,
td {
  padding: 1rem;
  text-align: left; /* Center align the text */
  border: none; /* Remove border */
  color: #000;
  font-family: 'AktivGrotesk-Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 3rem;
}

th {
  background-color: #495678;
  color: white;
  font-weight: 600;
}

tr:nth-child(even) {
  background-color: #f8f9fa;
}

tr:hover {
  background-color: #e9ecef;
}

/* Tag ID clickable styles */
.assets-list-grid tr td:first-child {
  cursor: pointer;
  font-weight: bold; /* Make the tag ID stand out */
}

.assets-list-grid tr:hover td:first-child {
  color: #0056b3;
}

/* Modal table styles */
.modal-body table tr td:first-child {
  border-bottom: none; /* Remove underline from the first column of modal table */
}

/* Fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

table {
  animation: fadeIn 1s ease-in;
}

/* Modal styles */
.modal-header,
.modal-body {
  background-color: #495678;
  padding: 1.5rem;
  /* border-radius: 12px 12px 0px 0px ; */
}

/* Add cross icon to close button */
.modal-header .close {
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-header .close:before {
  content: "\00d7";
}

/* Click animation */
.clicked {
  transform: scale(0.9);
  transition: transform 200ms ease-in-out;
}

/* Mobile view card styles */
@media screen and (max-width: 767px) {
  .table {
    display: block;
    overflow-x: auto;
  }
  .rt-tr-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    background-color: #f8f9fa;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .rt-td {
    border-bottom: 1px solid #dee2e6;
    padding: 0.75rem;
  }
  .rt-td:last-child {
    border-bottom: none;
  }
}

/* Add the loader styles */

.loader-wrapper {
  display: flex;
  flex-direction: column; /* Add this line */
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
}

.loader {
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-top: 0.5em solid #007bff;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

 /* Add the loading text styles and animation */

 .loading-text {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #3498db;
  animation: loadingTextAnimation 1.5s infinite;
}

@keyframes loadingTextAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


/* Modal Table Design */

.custom-modal {
  border: none;
  background-color: #f8f9fa;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.modal-header {
  padding: 1.5rem;
}

.modal-body {
  padding: 1.5rem;
  background-color: white;
  text-align: left;
}

.modal-footer {
  padding: 1.5rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
} 

.reason-label{
  color: #757575;
  font-family: 'AktivGrotesk-Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 1.5%;
}

.reason-text{
  border-radius: 12px;
  border: 1px solid #DEDEDE;
  background: #FFF;
  margin-top: 10px;
  /* color: #D8D7D7; */
  font-family: 'AktivGrotesk-Regular', sans-serif;
}

.submit {
    border-radius: 4px;
    border-radius: 12px;
    border: 1px solid #E67817;
    background: #E67817;
    font-family: 'AktivGrotesk-Regular', sans-serif;
    color: white;
    font-weight: 700;
    font-size: 20px;
    line-height: normal;
    padding: 10px 30px;
    margin-top: -20px;
}

.new-field{
  border: 1px solid #DEDEDE;
  border-radius: 12px;
}



/* Modal input fields and elements */
.modal-body input[type="text"],
.modal-body textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  padding-bottom: 50px;
  padding-left: 12px;
}

.modal-body label {
  margin-top: 10px;
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.modal-body button {
  width: 225px;
  color: grey;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  font-size: 16px;
  font-weight: 600;
  border-bottom: grey;
  font-family: "Montserrat";
}

.modal-body button:hover {
  color: black;
}


.modal-body .form-group {
  margin-bottom: 1rem;
}

/* Add styles for Add New Asset button and close button */
.add-new-asset-btn {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #495678;
  color: white;
  border: none;
}

.add-new-asset-btn:hover {
  background-color: #1a1a1a;
  color: white;
}

.new-asset-fields {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0.7rem;
}


.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-control {
  border-radius: 12px;
  border: 1px solid #DEDEDE;
  background: #FFF;
  width: 100%;
  padding: 15px 12px;
  margin-bottom: 30px;
  margin-top: 10px;
  /* color: #757575; */
  height: auto;
}


/* Add margin between select category dropdown and nav-tabs */
.nav-tabs {
  margin: 20px 0;
}

.select-asset-tabs .nav-item {
  width: 50%;

}

.select-asset-tabs .nav-link {
  width: 100%;
  border : none;
  background: white;
  color: black;
  font-family: 'AktivGrotesk-Regular', sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: left;

}

.nav-tabs .nav-link.active {
  color: #F58220;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 3px solid #F58220;
  font-family: 'AktivGrotesk-Regular', sans-serif;
  font-weight: 700;
  text-align: left;
  border-radius: 0%;
  outline: none;

}

/* Remove the add new asset and close button from the new tab */
#addNewAssetForm .add-new-asset-btn,
#addNewAssetForm .btn-close-new-asset {
  display: none;
}

/* Reduce the margin between make, model, specification text input in the new tab */
#addNewAssetForm .form-group {
  margin-bottom: 0.5rem;
}

/* Add transition animation on tab switching */
.nav-link {
  transition: background-color 0.3s ease-in-out;
}

/* Make the modal look aesthetic */
.modal-content {
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  background: #f8f9fa;
  border: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 22px;
  color: black;
  padding-top: 20px;
  font-weight: 500;
  margin-left: 30px;
  margin-bottom: 15px;
}


.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.chevron-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-right: 30px;
  display: flex;
  margin-left: 20px;  
}

.filter-box {
  width: 92px;
  margin-left: 5px; /* Adjust as needed */
  background-color: #495678;
  color: white;
  font-weight: 400;
}

.asset-info-head{
  background-color: #495678;
  border: none;
}

.modal-title{
  color: white;
}

.close-btn{
  color: white;
}

.asset-info-td{
  font-weight: bold;
  text-align: left;
}

.modal-body .button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-body .asset-button {
  width: 151px;
  height: 45px;
  border-radius: 8px;
  background-color: #EEE;
  color: #000;
  text-align: center;
  font-family: 'AktivGrotesk-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 35px;
  outline: none;
  border: 1.3px solid #000;


}

.asset-button:hover{
  width: 151px;
  height: 45px;
  border-radius: 8px;
  background-color: #E67817;
  color: #FFF !important;
  text-align: center;
  font-family: 'AktivGrotesk-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 35px;
  outline: none;
}

.modal-body .button-group .asset-button:last-child {
  margin-right: 0; /* Remove margin from the right of the last button */
}

.request-asset-button {
  width: 167px;
  height: 45px;
  border-radius: 8px;
  background: #EEE;
  color: #000;
  font-family: 'AktivGrotesk-Medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: auto;
  border: 1.3px solid #000;
}

.tab-content{
  margin-top: 30px;
}

.maintenance-tag{
  color: #757575;
  font-family: 'AktivGrotesk-Regular', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.maintenance-asset{
  padding-bottom: 15px !important;
  border: none;
  color: #000;
  font-family: 'AktivGrotesk-Medium', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
}

.maintenance-reason{
  border-radius: 12px;
  border: 1px solid #DEDEDE;
  background: #FFF;
  margin-top: 10px;
  font-family: 'AktivGrotesk-Regular', sans-serif;

}

.maintenance-button{
  border-radius: 4px;
  border-radius: 12px;
  border: 1px solid #E67817;
  background: #E67817;
  font-family: 'AktivGrotesk-Regular', sans-serif;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
  padding: 10px 30px;
  margin-top: -20px;
}

.maintenance-button-back{
  border-radius: 4px;
  border-radius: 12px;
  border: 1px solid #495678;;
  background: #495678;;
  font-family: 'AktivGrotesk-Regular', sans-serif;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
  padding: 10px 30px;
  margin-top: -20px;
  
}

.status-filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #495678;
  color: white;
  height: 100px; /* Adjust as per requirement */
  width: 170px; /* Adjust as per requirement */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 2; /* so it appears on top of other content */
  position: absolute;
  right: 0;
  
}

.status-filter .filter-option {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.status-filter .filter-option input[type="checkbox"] {
  margin-right: 10px;
}

.status-filter .filter-option label {
  margin: 0;
}

/*CSS for confirmation modal*/

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.confirm-tag{
  color: #000;
  font-family: 'AktivGrotesk-Regular', sans-serif;
  font-weight: 500;
  margin-top: 20px;
}


.assets-box {
  max-height: 150px; /* The height for 3 asset-rows */
  overflow-y: auto; /* This will only show the scrollbar when the content inside exceeds the max-height */
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 40px;
}

.asset-row {
  display: flex;
  align-items: center;
  height: 50px;
}

.asset-row input[type="checkbox"] {
  margin-right: 10px;
}

.asset-status-prompt{
  font-family: 'AktivGrotesk-Regular', sans-serif;
  font-size: 14px;
  color: #757575;
  margin-top: 25px;
}