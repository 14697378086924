.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Adjust this to control the overall size of the NoData component */
  padding: 2em;
  box-sizing: border-box;
}

.no-data-image {
  margin-top: -80px;
  width: 350px; /* Adjust this to control the size of the image */
  height: 350px;
  overflow: hidden;
}

.no-data-text {
  font-size: 1.2em;
  margin-top: 1em;
}
h5 {
  color: gray;
}