@font-face {
  font-family: 'AktivGrotesk-Regular';
  src: url('../fonts/AktivGrotesk-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'AktivGrotesk-Medium';
  src: url('../fonts/AktivGrotesk-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'AktivGrotesk-Bold';
  src: url('../fonts/AktivGrotesk-Bold.otf') format('opentype');
}
