.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
}

.col-5 {
  margin-left: 90px;
}

.header {
  position: fixed;
  top: 0;
  font-family: 'AktivGrotesk-Bold', sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f58220;
  justify-content: center;
  margin-top: 25px;
  padding-right: 80px;
}

.nav-item-container {
  display: flex;
  justify-content: flex-end;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.dropdown-toggle {
  white-space: nowrap;
  margin-right: 100px;
}

.nav-link.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 3px;
  color: #333;
  font-family: 'AktivGrotesk-Medium', sans-serif;
  font-weight: 500;
  margin-top: 18px;
  font-size: 16px;
}

.dropdown-toggle:focus, .dropdown-toggle:active {
  outline: none;
}

.dropdown-toggle:hover{
  color: #f58220;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #495678;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

@media (max-width: 992px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .header {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
    .header {
      font-size: 16px;
    }
  
    .col-5,
    .col-4,
    .col-3 {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  
    .navbar-nav {
      display: flex;
      justify-content: center;
    }
  
    .nav-item-container {
      justify-content: center;
    }
  }
  
  /* Center the Asset Management text for small screens */
  @media only screen and (max-width: 576px) {
    .header {
      text-align: center;
    }
  }

.header {
  width: 193px;
  height: 26px;
  font-size: 20px; /* decrease font-size to 18px */
  line-height: 1.3;
  letter-spacing: normal;
  color: #f58220;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .row {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .col-5,
  .col-4,
  .col-3 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }
}

/* Adjust the Asset Management alignment for larger screens */
@media only screen and (min-width: 769px) {
    .header {
      margin-left: -200px;
    }
  }
  